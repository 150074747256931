<template>
  <div class="leftbig">
    <div class="leftTop">{{ formdata.topText }}</div>
    <div class="menu">
      <el-menu
        style="border-right: 0px"
        :default-active="
          active ? active :'/' + $route.path.split('/')[1] + '/' + $route.path.split('/')[2]
        "
        @select="handleSelect"
      >
        <el-menu-item
          v-for="(item, index) in formdata.menulist"
          :key="index"
          :index="item.path"
        >
          <i class="el-icon-menu"></i>
          <span slot="title">{{ item.title }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="applet">
      <img  :src="QRcode[0]"  :onerror="smallImgError" alt="小程序" />
    </div>
    <div class="oneToOne" @click="goOneToOne">
      <img src="@/assets/img/oneToOne.png" alt="1对1服务" />
    </div>
  </div>
</template>

<script>
import {
  getAdvertisementDetail, 
} from "@/api";
import { getArticleSubjectList } from "@/api/dynamicsOfCollegeEntranceExamination.js";
export default {
  props: {
    formdata: {
      type: Object,
      default: () => {
        return {
          QRcode: [],
        };
      },
    },
    active: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      menulist: [], //左侧菜单
      active: "", //激活项
    };
  },
  mounted() {
    // this.getArticleSubjectList();
    this.getImg();
  },
  methods: {
    goOneToOne(){
      this.$router.push("/volunterservce/oneServe");
    },
    async getImg() {
      let res = await getAdvertisementDetail({ id: 8 });
      if (res.code == 1) {
        this.QRcode = res.data.images;
        // this.$store.commit("setImage", res.data.images);
      }
    },
    // // 获取文章栏目
    // getArticleSubjectList() {
    //   getArticleSubjectList({ type: 2 }).then((res) => {
    //     if (res.code == 1) {
    //       this.menulist = res.data || [];
    //       this.active = this.menulist[0].subjectId;
    //       this.$emit("getsubjectId", this.menulist[0].subjectId);
    //     }
    //   });
    // },
    handleSelect(key, keyPath) {
      console.log("123");
      console.log(key);
      this.$emit("getsubjectId", key);
      this.$emit("gettype", key);
    },
  },
};
</script>

<style lang="less" scoped>
.oneToOne:hover{
  img{
    transform: scale(1.2);
  }
}
/deep/.el-menu-item {
  font-size: 16px !important;
}
/deep/.el-menu-item.is-active {
  color: #1ab394 !important;
}
/deep/.el-menu-item:focus,
.el-menu-item:hover {
  outline: 0;
  background-color: #fff;
  color: #1ab394 !important;
}
.leftbig {
  width: 260px;
  .leftTop {
    background: url(../../assets/img/leftmune_top_bg.jpg) center top no-repeat;
    border-radius: 5px;
    background-position: 100% auto;
    height: 106px;
    text-align: center;
    line-height: 106px;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
  }
  .menu {
    width: 100%;
  }
}
.applet {
      width: 100%;
      img {
        width: 160px;
        margin-left: 50px;
      }
    } 
</style>
