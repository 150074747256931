<template>
  <div class="block">
    <el-carousel trigger="click" height="350px">
      <el-carousel-item v-for="item in scrImg" :key="item">
        <!-- <h3 class="small">{{ item }}</h3> -->
        <!-- object-fit: contain -->
        <img style="width: 100%; height:350px;" :src="item" alt />
        <div v-html="content"></div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  props: {
    scrImg: {
      type: Array,
      default: () => {
        return [];
      },
    },
    content: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
</style>