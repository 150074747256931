<template>
  <div>
    <!-- 滚动图开始 -->
    <div class="scrollChart">
      <ScrollChart :scrImg="scrImg"></ScrollChart>
    </div>
    <!-- 滚动图结束 -->
    <!-- 内容开始 -->
    <div class="contentbotton">
      <!-- 左侧菜单开始 -->
      <div class="left">
        <menuLeft @gettype="getsubjectId" :formdata="formdata" :active="active"></menuLeft>
        <!-- <Leftmenu @getsubjectId="getsubjectId"></Leftmenu> -->
      </div>
      <!-- 左侧菜单结束 -->
      <!-- 右侧列表开始 -->
      <div class="right" v-if="isflag">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </div>
    </div>
    <!-- 内容结束 -->
  </div>
</template>

<script>
import {
  getAdvertisementDetail, // 顶部图片
} from "@/api/index.js";
import { getArticleSubjectList } from "@/api/dynamicsOfCollegeEntranceExamination.js";
import ScrollChart from "@/components/scrollChart";
import Leftmenu from "./components/leftmenu";
import menuLeft from "@/components/menu";
export default {
  components: {
    ScrollChart,
    Leftmenu,
    menuLeft,
  },
  data() {
    return {
      total: 0,
      current: 1,
      size: 10,
      active: "",
      scrImg: [], //轮播图
      datalist: [], //文章列表
      title: "", //文章标题
      isflag: true,
      form: {}, //文章详情
      subjectId: "",
      formdata: {
        topText: "高考动态",
        menulist: [],
      },
    };
  },
  mounted() {
    this.getTop();
    this.getArticleSubjectList()
  },
  methods: {
    // 获取文章栏目
    getArticleSubjectList() {
      getArticleSubjectList({ type: 1, source: 2 }).then((res) => {
        if (res.code == 1) {
          let data= res.data || [];
          data.forEach(item => {
            this.formdata.menulist.push({path:item.subjectId,title:item.subject})
          });
          if (this.$route.query.subjectId) {
            this.active = this.$route.query.subjectId;
          } else {
            this.active = data[0].subjectId + '';
          }
          this.getsubjectId(this.active);
        }
      });
    },
    // 顶部轮播图片
    async getTop() {
      let res = await getAdvertisementDetail({ id: 6 });
      if (res.code == 1) {
        this.scrImg = res.data.images;
      }
      function getimgsrc(html) {
        var reg = /<img.+?src=('|")?([^'"]+)('|")?(?:\s+|>)/gim;
        var arr = [];
        let tem = [];
        while ((tem = reg.exec(html))) {
          arr.push(tem[2]);
        }
        console.log(arr);
        return arr;
      }
    },
    getsubjectId(subjectId) {
      this.$router.push({
        path: "/dynamicsOfCollegeEntranceExamination/articellist",
        query: { subjectId: subjectId },
      });
    },
    // 去详情页
    todetails(id) {
      this.$router.push({
        path: "/dynamicsOfCollegeEntranceExamination/detial",
        query: { id: id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.scrollChart {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 40px;
}
.contentbotton {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
}
</style>
