<template>
  <div class="leftbig">
    <div class="leftTop">高考动态</div>
    <div class="menu">
      <el-menu
        style="border-right: 0px"
        :default-active="active"
        @select="handleSelect"
      >
        <el-menu-item
          v-for="(item, index) in menulist"
          :key="item.subjectId + index"
          :index="item.subjectId"
        >
          <i class="el-icon-menu"></i>
          <span slot="title">{{ item.subject }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="applet">
      <img :src="QRcode[0]" :onerror="smallImgError" alt="小程序" />
    </div>
    <div class="oneToOne" @click="goOneToOne()">
      <img src="../../../assets/img/oneToOne.png" alt="1对1服务" />
    </div>
  </div>
</template>

<script>
import { getAdvertisementDetail } from "@/api";
import { getArticleSubjectList } from "@/api/dynamicsOfCollegeEntranceExamination.js";
export default {
  data() {
    return {
      menulist: [], //左侧菜单
      active: "", //激活项
      QRcode: [],
    };
  },
  mounted() {
    this.getArticleSubjectList();
    this.getQRcode();
  },
  methods: {
    goOneToOne(){
      this.$router.push("/volunterservce/oneServe");
    },
    // 小程序图片
    async getQRcode() {
      let res = await getAdvertisementDetail({ id: 8 });
      if (res.code == 1) {
        this.QRcode = res.data.images;
      }
    },
    // 获取文章栏目
    getArticleSubjectList() {
      getArticleSubjectList({ type: 2 }).then((res) => {
        if (res.code == 1) {
          this.menulist = res.data || [];
          if (this.$route.query.subjectId) {
            this.active = this.$route.query.subjectId;
          } else {
            this.active = this.menulist[0].subjectId;
          }

          this.$emit("getsubjectId", this.active);
        }
      });
    },
    handleSelect(key, keyPath) {
      console.log("123");
      console.log(key);
      this.$emit("getsubjectId", key);
    },
  },
};
</script>

<style lang="less" scoped>
.oneToOne:hover{
  img{
    transform: scale(1.2);
  }
}
/deep/.el-menu-item {
  font-size: 16px !important;
}
/deep/.el-menu-item.is-active {
  color: #1ab394 !important;
}
/deep/.el-menu-item:focus,
.el-menu-item:hover {
  outline: 0;
  background-color: #fff !important;
  color: #1ab394 !important;
}
.leftbig {
  width: 260px;
  .leftTop {
    background: url(../../../assets/img/leftmune_top_bg.jpg) center top
      no-repeat;
      border-radius: 5px;
    background-position: 100% auto;
    height: 106px;
    text-align: center;
    line-height: 106px;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
  }
  .menu {
    width: 100%;
  }
  .applet {
      width: 100%;
      img {
        width: 160px;
        margin-left: 50px;
      }
    }
}
</style>
